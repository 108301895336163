@import url('https://cdn.syncfusion.com/ej2/material.css');

.sidebar {
  box-shadow: rgb(113 122 131 / 11%) 0px 7px 30px 0px;
}
.nav-item,
.navbar {
  z-index: 10000;
}
@media  screen and (max-width:800px) {
  .sidebar{
    z-index: 10000000;
  }
}

.e-dlg-center-center, .e-quick-popup-wrapper.e-device{
  z-index: 1000000 !important;
}

::-webkit-scrollbar {
  width: 6px;
}
::-webkit-scrollbar-thumb {
  background-color: rgb(216, 216, 216);
  border-radius: 40px;
}
::-webkit-scrollbar-track {
  background-color: transparent;
}

/* color-picker style  */

 #preview {
  background: transparent
    url('https://ej2.syncfusion.com/react/demos/src/color-picker/images/pen.png')
    no-repeat;
  display: inline-block;
  height: 80px;
  margin: 10px 0;
  min-width: 300px;
  max-width: 600px;
  background-color: #008000;
}

.e-input-group:not(.e-float-icon-left), .e-input-group.e-success:not(.e-float-icon-left), .e-input-group.e-warning:not(.e-float-icon-left), .e-input-group.e-error:not(.e-float-icon-left), .e-input-group.e-control-wrapper:not(.e-float-icon-left), .e-input-group.e-control-wrapper.e-success:not(.e-float-icon-left), .e-input-group.e-control-wrapper.e-warning:not(.e-float-icon-left), .e-input-group.e-control-wrapper.e-error:not(.e-float-icon-left){
  border: none;
}

/* Styles to hide the expand icon 
.e-grid .e-detailrowcollapse, .e-grid .e-detailrowexpand {
  display: none;
}
.e-grid .e-detailrowcollapse::before, .e-grid .e-detailrowexpand::before {
  content: '';
} */

.e-row[aria-selected="true"] .e-customizedExpandcell {
  background-color: #e0e0e0;
}
.e-rowcell{
  font-family:"Altone", sans-serif;
}
.e-grid.e-gridhover tr[role='row']:hover {
  background-color: #838181;
}

.custom-center-text {
  position: absolute;
  top: 36%; /* Adjust the top position */
  left: 7%; /* Adjust the left position */
  transform: translate(-50%, -50%); /* Center the text using transform */
  /* Add any other styles you want */
}

.hover:bg-gray-300 {
  width: 100%; /* Change this value to adjust the hover width */
  
}

.nested-link:hover {
  width: 100%; /* or any specific width you desire for the nested links on hover */
 
}

.nested-link{
    font-weight: bold;
}

/* Add this CSS to your stylesheet */

/* Add this CSS to your stylesheet */

.grid-tab-buttons {
  display: flex;
  margin-bottom: 10px;
}

.grid-tab-buttons button {
  flex: 1;
  padding: 10px 20px;
  margin-right: 5px; /* Adjust the margin value as per your preference */
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #f2f2f2;
  color: #333;
  font-size: 16px;
  cursor: pointer;
}

.grid-tab-buttons button:last-child {
  margin-right: 0; /* Remove margin from the last button to avoid extra spacing */
}

.grid-tab-buttons button.active {
  background-color: #007bff;
  color: #fff;
  border-color: #007bff;
}





.e-grid .e-headercell.customcss {
  background-color: #c0c0c0;
  color: rgb(0, 0, 0);
  padding-left: 5;
  grid-template-rows: auto auto;
  font-family:"Altone", sans-serif;
}


.highlighted-row {
  background-color: rgb(136, 136, 136); /* Change this to the desired background color */
}


/* styles.css or your custom CSS file */
.custom-header {
  background-color: #c0c0c0;
  color: rgb(0, 0, 0);
  padding-left: 5px;
  grid-template-rows: auto auto;
  font-family:"Altone", sans-serif;
  font-size: 16px;
}

.grid-container {
  width: 1920px; /* Adjust this value as per your requirement */
  margin: 0 left; /* To center the table container horizontally */
}



/* Target the input element using its classes and role attribute */
.e-grid .e-field.e-input.e-defaultcell.e-control.e-textbox.e-lib[role="textbox"][name="APRD QTY"] {
  /* Your custom styles here */
  border: 2px solid #3092ee;
  box-shadow: 0 0 25px rgba(27, 113, 194, 0.5);
  border-radius: 5px;
  font-family:"Altone", sans-serif;
  /* Add any other styles you want */
}

/* Add hover effect for the active link */
.active-link {
  background-color: rgb(158, 158, 158) !important;
  font-weight: bold;
}

.active-link:hover {
  background-color: rgb(143, 141, 141);
  font-weight: bold;
  /* Add any other styles you want for the active link on hover */
}

/* Add this to your existing CSS */
.active-link-hover:hover {
  background-color: #a8a6a6; /* Change this to your desired hover background color */
  /* Add any other styles you want for the active link on hover */
}

/* Add this CSS to your existing stylesheet */

/* Styles for sublinks on hover */
.sublink-hover:hover {
  background-color: #b8b8b8;
  width: calc(100% + 2rem); /* Adjust the value based on your sidebar's padding or margin */
  margin-left: -1rem; /* Adjust the value based on your sidebar's padding or margin */
  padding-left: 1rem;
}

/* Styles for active sublinks */
.active-sublink {
  background-color: #bdbdbd;
  width: calc(100% + 2rem); /* Adjust the value based on your sidebar's padding or margin */
  margin-left: -1rem; /* Adjust the value based on your sidebar's padding or margin */
  padding-left: 1rem;
}

/* In your CSS file */
.buttonStyle {
  margin-right: 10px;
  padding: 8px 12px;
  background-color: #535c53;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.e-grid .e-headercelldiv {
  font-size: 16px; /* Change this value to your desired font size */
  /* Other styles remain unchanged */
}

.e-grid .e-headercell {
  background-color: #8a8b8a;
  font-family:"Altone", sans-serif;
  font-size: 18px;
  color:#333;
}

.file-upload-container {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 10px; /* Adjust the gap as needed */
}

.file-upload-container label {
  text-align: left;
  grid-row: span 1; /* Make labels span all five columns */
}

.file-upload-container input {
  width: 100%;
}

